import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import BaseService from 'services/api/BaseService';
import { NotificationsUrls } from 'services/api/urls';
import { Notifications } from 'types/Notification';

export const useNotifications = (): any => {
  const { t } = useTranslation();

  // Récupération des notifications
  const result = useQuery<Notifications, Error>(['notifications'], async () => {
    let data: any;
    try {
      const response = await BaseService.getRequest(
        NotificationsUrls.GET,
        true,
      );
      data = await response.json();
      if ([200, 201].includes(response.status)) {
        return data;
      }
    } catch (e: any) {
      console.warn('Get notifications error', e);
      throw new Error(t('global:internet_connexion_error'));
    }
    throw new Error(data?.message || t('global:internet_connexion_error'));
  });

  // Marque les notifications comme lues et lance un refetch pour rafraichir
  const refetch = result.refetch;
  const markAsRead = useCallback(async () => {
    let data: any;
    try {
      const response = await BaseService.putRequest(
        NotificationsUrls.MARK_READ,
        {},
        true,
      );
      data = await response.json();
      if ([200, 201].includes(response.status)) {
        refetch();
        return data;
      }
    } catch (e: any) {
      console.warn('Mark notifications read error', e);
      throw new Error(t('global:internet_connexion_error'));
    }
    throw new Error(data?.message || t('global:internet_connexion_error'));
  }, [t, refetch]);

  return useMemo(() => ({ ...result, markAsRead }), [markAsRead, result]);
};

export default useNotifications;
