import React, { useState, useEffect } from 'react';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from 'hooks/useDateFormat';
import companyInfo from 'const/companyInfo';
import { ReactComponent as WhiteLogo } from 'assets/icons/WhiteLogo.svg';

export const headerHeight = 20; // mm
export const footerHeight = 20; // mm
export const padding = 8; // mm

const PrintLayout = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [title, setTitle] = useState(document.title);
  const { t } = useTranslation();
  const format = useDateFormat();

  // Injecte la CSS spécifique pour l'impression du layout
  useEffect(() => {
    const style = document.createElement('style');
    // Impression au format paysage sans marge
    // Forcer l'impression des graphiques et images d'arrière-plan
    style.innerHTML = `
      @page {
        size: A4;
        margin: 0;
        size: landscape;
      }
      @media print {
        html, body {
          width: 297mm !important;
          height: 210mm !important;
          -webkit-print-color-adjust:exact !important;
          print-color-adjust:exact !important;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Actualise le titre quand document.title est modifié
  useEffect(() => {
    setTitle(document.title);
    const observer = new MutationObserver(() => {
      setTitle(document.title);
    });
    const titleElement = document.querySelector('title');
    if (titleElement) {
      observer.observe(titleElement, { childList: true });
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {/* Header  */}
      <header
        className="fixed top-0 w-full flex justify-items-center items-center text-Bleu-hellio"
        style={{
          height: `${headerHeight}mm`,
          padding: `${padding}mm`,
        }}
      >
        <WhiteLogo
          style={{
            height: '7.5mm',
            marginRight: '10pt',
          }}
        />
        <Flex
          vertical
          className="grow justify-items-stretch text-Gris-hellio"
          style={{
            marginTop: '-5.75pt',
          }}
        >
          <h1
            className="font-bold m-0 p-0"
            style={{
              fontSize: '16pt',
              lineHeight: '19.5pt',
            }}
          >
            {title}
          </h1>
          <p
            className="p-0"
            style={{
              fontSize: '8pt',
              lineHeight: '8pt',
            }}
          >
            {t('global:export_date', {
              date: format(new Date(), 'dd/MM/yyyy'),
            })}
          </p>
        </Flex>
      </header>

      {/* Footer */}
      <footer
        className="fixed bottom-0 w-full flex justify-items-center items-center bg-Bleu-hellio text-white"
        style={{ height: `${footerHeight}mm`, fontSize: '8pt' }}
      >
        <ul className="grow text-center">
          <li key="companyLogo">
            <WhiteLogo
              className="inline-block text-white"
              style={{
                height: '18pt',
                marginTop: '3pt',
                marginBottom: '3pt',
              }}
            />
          </li>
          <li key="companyName" className="font-normal">
            {companyInfo.name}
          </li>
          <li key="address" className="font-thin">
            {companyInfo.address} |{' '}
            <a href={`mailto:${companyInfo.email}`} className="!text-white">
              {companyInfo.email}
            </a>
          </li>
        </ul>
      </footer>

      {/* Tableau pour réserver l'espace du header et du footer et éviter les chevauchements */}
      <table className="w-full">
        {/* Marge pour le header  */}
        <thead style={{ height: `${headerHeight}mm` }}>
          <tr>
            <th>&nbsp;</th>
          </tr>
        </thead>

        {/* Marge pour le footer */}
        <tfoot style={{ height: `${footerHeight}mm` }}>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </tfoot>

        {/* Contenu  */}
        <tbody
          style={{
            marginTop: `${headerHeight}mm`,
            marginBottom: `${footerHeight}mm`,
            padding: `0 ${padding}mm`,
          }}
        >
          <tr>
            <td>{children}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default React.memo(PrintLayout);
