export enum DossierStatus {
  'Etude en cours',
  'Offre émise',
  'Chantier en cours',
  'Documents en attente',
  'Dossier reçu',
  'En attente de passage bureau de contrôle',
  'Dossier incomplet',
  'Dossier validé',
  'Prime Pro payée',
  'Part Bénéficiaire payée',
  'Prime Globale Payée',
  'Refusé',
  'Abandon',
  "Demande d'informations",
  'Dossier en cours de validation',
}

export interface Base64File {
  name: string;
  content: string;
  type?: string;
}

export interface DossierHistoryItem {
  date: string;
  value: DossierStatus;
}

export class Entreprise {
  readonly raisonSociale?: string;
  readonly siret?: string;
  readonly adresse?: string;
  readonly codePostal?: string;
  readonly ville?: string;
  readonly email?: string;
  readonly telephone?: string;
}

export interface Chantier {
  reference?: string;
  montant?: string;
  entreprise?: Entreprise;
  natureTravaux?: string;
  dateDebutTravaux?: string;
  dateFinTravaux?: string;
}

export interface Site {
  nom?: string;
  usage?: string;
  adresse?: string;
  codePostal?: string;
  ville?: string;
}

export interface Batiment {
  ancien?: boolean;
  ancienneEnergieChauffage?: string;
  nouvelleEnergieChauffage?: string;
  surfaceChauffee?: number;
  surfaceChauffeeRehabilitee?: number;
  surfaceVentileeRehabilitee?: number;
}

export interface Personne {
  firstname?: string;
  lastname?: string;
  email?: string;
}
export interface Beneficiaire extends Personne {
  raisonSociale?: string;
  siren?: string;
  adresse?: string;
  codePostal?: string;
  ville?: string;
  civilite?: string;
  telephone?: string;
}

export interface DossierDetails {
  id: string;
  statut: DossierStatus;
  chantier?: Chantier;
  site?: Site;
  beneficiaire?: Beneficiaire;
  referentCommercial?: Personne;
  proprietaire?: Personne;
  history: DossierHistoryItem[];
}

export interface DossierLead {
  typeOperation?: string;
  description?: string;
  beneficiaire: Beneficiaire;
  referentCommercial: Personne;
  site: Site;
  batiment: Batiment;
  chantier: Chantier;
  modeleIncitationFinanciere?: string;
  optin?: boolean;
}

export interface CreateDossierLead {
  dossier: DossierLead;
  files: Base64File[];
}
