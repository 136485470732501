import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';

interface RoleGuardProps {
  children: ReactElement;
  allowedRoles?: string[];
  allowedTypes?: string[];
}

function RoleGuard(props: RoleGuardProps) {
  const { children, allowedRoles, allowedTypes } = props;
  const { auth } = useAuthContext();

  if (!auth?.token) {
    return <Redirect to="/connexion" />;
  }

  if (allowedRoles && !allowedRoles.includes(auth?.user.role || '')) {
    return <Redirect to="*" />;
  }

  if (allowedTypes && !allowedTypes.includes(auth?.user.type || '')) {
    return <Redirect to="*" />;
  }

  return children;
}

export default RoleGuard;
