import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  List,
  Badge,
  Button,
  Divider,
  Flex,
  Popover,
  Spin,
  Typography,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg';
import { useNotifications } from 'hooks/useNotifications';
import { Notification } from 'types/Notification';

const REFETCH_PERIOD = 60; // En secondes

export const NotificationPopover = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const refetchInterval = useRef();
  const { data, isLoading, markAsRead, refetch } = useNotifications();
  const hasNotifications = !!data?.notifications?.length;
  const notificationsCount = data?.unread ?? 0;

  // Refetch périodique des notifications
  useEffect(() => {
    clearInterval(refetchInterval.current);
    refetchInterval.current = setInterval(
      refetch,
      REFETCH_PERIOD * 1000,
    ) as any;
    return () => clearInterval(refetchInterval.current);
  }, [refetch]);

  return (
    <Popover
      onOpenChange={(visible) => setOpen(visible)}
      open={open}
      rootClassName="no-padding-popover white-popover"
      content={
        <div className="w-96">
          <div className="flex justify-between items-center pl-6 pr-3 py-3">
            <Typography.Paragraph className="!mb-0 font-bold">
              {t('notification:title')}
            </Typography.Paragraph>
            <Button
              type={'text'}
              icon={<CloseOutlined />}
              onClick={() => setOpen(false)}
            />
          </div>

          <Divider className="m-0" />

          <div className="py-1 px-4">
            {isLoading && (
              <Flex align="center" justify="center">
                <Spin />
              </Flex>
            )}

            {!isLoading && !hasNotifications && (
              <Typography.Paragraph className="!mb-0">
                {t('notification:no_notification')}
              </Typography.Paragraph>
            )}

            {data && hasNotifications && (
              <List
                size="small"
                dataSource={data.notifications}
                renderItem={({ type, data, readDate }: Notification, index) => (
                  <List.Item
                    className={clsx(' hover:bg-gray-50', {
                      'font-semibold': !readDate,
                    })}
                  >
                    <Typography.Link
                      href={
                        `/dossiers/${data.dossier.id}` +
                        (data.operation.id ? `/${data.operation.id}` : '')
                      }
                      key={index}
                      className="!text-gray-900"
                    >
                      {t(`notification:message:${type}`, data)}
                    </Typography.Link>
                  </List.Item>
                )}
              />
            )}
          </div>

          {hasNotifications && (
            <Fragment>
              <Divider className="m-0" />
              <Flex vertical align="flex-end">
                <Button className="mx-6 my-4" onClick={markAsRead}>
                  {t('notification:mark_as_read')}
                </Button>
              </Flex>
            </Fragment>
          )}
        </div>
      }
      placement="bottom"
    >
      {hasNotifications ? (
        <Badge
          className="flex h-full w-full text-white"
          count={notificationsCount}
          size="small"
        >
          <BellIcon height={24} width={24} />
        </Badge>
      ) : (
        <BellIcon />
      )}
    </Popover>
  );
};
